<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('sending_sms')"
                        :isNewButton="checkPermission('semester_store')"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sending_sms')"
                              :isNewButton="checkPermission('semester_store')"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('id')">
                            <b-form-input v-model="datatable.queryParams.filter.id">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            />

            <CommonModal ref="showModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t('sending_sms_with_mobile_phone_information').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                  <SendingSmsWithMobilePhoneInformation v-if="formProcess==='sendingSmsWithMobilePhoneInformation'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
// Services
import SemesterService from "@/services/SemesterService";

// Page

// Other
import qs from "qs";
import SendingSmsWithMobilePhoneInformation from "@/modules/sendSms/pages/SendingSmsWithMobilePhoneInformation.vue";

export default {
    components: {
        SendingSmsWithMobilePhoneInformation,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("sending_sms"),
        };
    },
    data() {
        return {
            text: null,
            formId: 0,
            form: {},
            formatLines:[],
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'semester_show',
                                callback: (row) => {
                                    this.showForm(row);
                                },
                            },

                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: "name",
                        sortable: true,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        showForm(row) {
            if (row.name === 'Cep Telefonu Bilgisi ile SMS Gönderimi') {
                this.formProcess='sendingSmsWithMobilePhoneInformation'
                this.$refs.showModal.$refs.commonModal.show()
            }
            if (row.name === 'Öğrenci Arama İle SMS Gönderimi') {
                this.$router.push('/send/sms/search/student')
            }
            if (row.name==='Personellere SMS Gönderimi'){
                this.$router.push('/send/sms/to-staff')
            }
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return SemesterService.getAll(config)
                .then((response) => {
                    let data = [
                        {
                            id: '123',
                            name: 'Öğrenci Arama İle SMS Gönderimi'
                        },
                        {
                            id: '1234',
                            name: 'Cep Telefonu Bilgisi ile SMS Gönderimi'
                        },
                        {
                            id: '12345',
                            name: 'Personellere SMS Gönderimi'
                        },
                        {
                            id: '123456',
                            name: 'Excel ile Değişken SMS Gönderimi'
                        },


                    ]
                    this.datatable.rows = data
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
    },
};
</script>
<style>
.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 15px;
}
</style>
