<template>
    <div>
        <b-row>
            <b-col cols="12" md="12" lg="12" xl="12">
                <b-form-group :label="$t('orjinator')">
                    <parameter-selectbox v-model="form.name">
                    </parameter-selectbox>
                </b-form-group>
                <b-form-group :label="$t('explanation')">
                    <b-form-input v-model="form.name"></b-form-input>
                </b-form-group>
                <b-input-group prepend="TR" class="mb-2">
                    <b-form-textarea
                        id="textarea-rows"
                        v-model="form.objectives_tr"
                        rows="10"
                    />
                </b-input-group>
                <b-input-group prepend="EN">
                    <b-form-textarea
                        id="textarea-rows"
                        v-model="form.objectives_en"
                        rows="10"
                    />
                </b-input-group>

                <MultiTextPhoneNumber
                  v-model="form.text"
                />

                <b-button variant="primary" class="mt-4" @click="store">
                    {{$t('send_sms')}}
                </b-button>
            </b-col>

        </b-row>

    </div>
</template>
<script>
import MultiTextPhoneNumber from "@/components/interactive-fields/MultiTextPhoneNumber.vue";

export default {
    components: {MultiTextPhoneNumber},
    metaInfo() {
        return {
            title: this.$t('sending_sms_student_search')
        }
    },
    data() {
        return {
            text:null,
            formId: null,
            formProcess: null,
            selectedRowsData: [],
            showButton: true,
            form: {},
        }
    },
    watch:{
      selectedRowsData(value){
          value.length!=0?this.showButton=false:this.showButton=true
      }
    },
    methods: {
        store(){
            console.log(this.form)
        }


    }
}
</script>



